<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <ConfirmWithdraw ref="confirmWithdraw" />
    <!--<v-app-bar-nav-icon class="d-flex" @click="drawer = true"></v-app-bar-nav-icon>-->
    <a href="/">
      <v-toolbar-title style="cursor: pointer">
        <img :src="logoPath" alt="test" style="width:200px;height:100px"/>
      </v-toolbar-title>
    </a>

    <v-spacer></v-spacer>

    <!-- App Bar content for home page -->
    <!--
    <template v-if="!isClaimRoute">
      <v-btn v-if="!isClaimRoute && !showVerticalDots" href="/contact" text>
        <span>Contact Us</span>
      </v-btn>
      <v-btn v-if="!showVerticalDots" href="#" text>
        <span>Have an Account</span>
      </v-btn>
      
      <template v-if="showVerticalDots">
        <v-menu
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              light
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              href="/contact"
            >
              <v-list-item-title>Contact Us</v-list-item-title>
            </v-list-item>
            <v-list-item
              href="/#"
            >
              <v-list-item-title>Have an Account</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </template>
    -->

    <!-- App Bar content once inside claim -->
    <template v-if="canWithdraw">
      <v-menu
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            v-on="on"
          >
            Actions
            <v-icon large>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <!--
          <v-list-item
            href="/contact"
          >
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="/#"
          >
            <v-list-item-title>Have an Account</v-list-item-title>
          </v-list-item>
          -->
          <v-list-item @click="openConfirmWithdrawDialog">
            <v-list-item-title>Withdraw Claim</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmWithdraw from './dialogs/ConfirmWithdraw'

export default {
  name: 'AppBar',

  components: {
    ConfirmWithdraw
  },

  data: () => ({
    verticalIconBreakpoint: 550
  }),

  computed: {
    ...mapState({
      resellerLogo: (state) => state.reseller.settings.logo,
      claimState: (state) => state.claim.claim
    }),

    isClaimRoute() {
      return this.$route.name === 'claim-token'
    },

    canWithdraw() {
      if (this.claimState !== null) {
        return (this.isClaimRoute && this.claimState.claim_CanWithdrawal === '1' && this.claimState.claim_id !== '0')
      } else {
        return false
      }
    },

    showVerticalDots() {
      return this.$vuetify.breakpoint.width < this.verticalIconBreakpoint
    },

    logoPath() {
      return this.resellerLogo || require('../assets/logo.svg')
    }
  },

  methods: {
    openConfirmWithdrawDialog() {
      this.$refs.confirmWithdraw.withdrawDialog = true
    }
  }
}

</script>
<style scoped>

</style>
